import { GlobalConfig } from "config";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const { WEB_CHAT_URL } = GlobalConfig;

const Webchat = () => {
  const { state } = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div style={{ top: 0, right: 0, bottom: 0, left: 0, zIndex: 9999, position: 'fixed', background: "white" }}>
      {!isLoaded && <div className="h-100 d-flex justify-content-center align-items-center">Logging into your worksatation...</div>}
      <iframe
        title="HookZOffice Webchat"
        src={`${WEB_CHAT_URL}${state.sessionId}`}
        allow="camera *;microphone *"
        className={`w-100 h-100 ${
          isLoaded ? "d-block" : "d-none"
        }`}
        onLoad={handleIframeLoad}
      ></iframe>
    </div>
  );
};

export default Webchat;
