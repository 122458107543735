import { GlobalConfig } from "config";
import { authHeader, handleError, handleResponse } from "helpers";
import axios, { AxiosResponse } from "axios";

const { baseUrl, v3 } = GlobalConfig;

export const userService = {
  signUp,
  verifyEmail,
  resendVerificationCode,
  signIn,
  setUpPassword,
  getUserDetails,
  forgotPassword,
  resetPassword,
  updateProfile
};

function signUp(data: object): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v3}/signup`, data, {
      headers: authHeader(),
    })
    .then(handleResponse)
    .catch(handleError);
}

function verifyEmail(data: string): Promise<AxiosResponse> {
  return axios
    .get(`${baseUrl}/${v3}/verify_email/${data}`)
    .then(handleResponse)
    .catch(handleError);
}

function resendVerificationCode(data: object): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v3}/resendemailverification`, data, {
      headers: authHeader(),
    })
    .then(handleResponse)
    .catch(handleError);
}

function signIn(data: object): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v3}/signin`, data, {
      headers: authHeader(),
    })
    .then(handleResponse)
    .catch(handleError);
}

function setUpPassword(data: object): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v3}/setuppassword`, data, {
      headers: authHeader(),
    })
    .then(handleResponse)
    .catch(handleError);
}

function getUserDetails(): Promise<AxiosResponse> {
  return axios
    .post(
      `${baseUrl}/${v3}/mydetail`,
      {},
      {
        headers: authHeader(),
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

function forgotPassword(data: object): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v3}/forgetpassword`, data, {
      headers: authHeader(),
    })
    .then(handleResponse)
    .catch(handleError);
}

function resetPassword(data: object): Promise<AxiosResponse> {
  return axios
    .post(
      `${baseUrl}/${v3}/resetpassword`,
      data,
      {
        headers: authHeader(),
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

function updateProfile(data: object): Promise<AxiosResponse> {
  return axios
    .post(
      `${baseUrl}/${v3}/updateprofile`,
      data,
      {
        headers: authHeader(),
      }
    )
    .then(handleResponse)
    .catch(handleError);
}
