import React, {
  ChangeEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

// packages
import classNames from "classnames/bind";

// assets
import avatar from "assets/icons/avatar.svg";
import camera from "assets/icons/camera.svg";
import cross from "assets/icons/cross.svg";

// css
import styles from "./userDetails.module.css";
import { AxiosResponse } from "axios";
import { userService, workstationsService } from "services";
import { ResponseData, UploadLogoResponseData } from "interfaces";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { logout } from "helpers";

const cx = classNames.bind(styles);

interface UserDetails {
  nickname: string;
  avatar: string;
  bio: string;
}

const UserDetails: React.FC = (): JSX.Element => {
  const { state } = useLocation();
  const isSignUp = state?.isSignUp;
  const navigate: NavigateFunction = useNavigate();
  const { user } = useSelector(
    (state: RootState) => state.user
  );

  const imageRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const [userDetails, setUserDetails] = useState<UserDetails>({
    nickname: "",
    bio: "",
    avatar: "",
  });
  const [uploadingImageError, setUploadingImageError]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("");
  const [generalError, setGeneralError]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("");
  const [btnText, setBtnText]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("Next");
  const [isBtnDisabled, setIsBtnDisabled]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(true);
  const [uploadingImage, setUploadingImage]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [logoForPreview, setLogoForPreview] = useState("");

  useEffect(() => {
    if (userDetails.nickname) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [userDetails]);

  function handleImageSelect(e: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile: File | null = e.target.files?.[0] || null;
    if (!selectedFile) {
      setGeneralError("Error while selecting a file.");
    } else {
      handleUploadLogo(selectedFile);
    }
  }

  async function handleUploadLogo(selectedFile: File) {
    try {
      // setBtnText("Loading...");
      const formData = new FormData();
      formData.append("image", selectedFile);
      setUploadingImage(true);
      setIsBtnDisabled(true);
      const response: AxiosResponse<ResponseData> =
        await workstationsService.uploadImageAndGetLink(formData);

      const responseData =
        response.data as UploadLogoResponseData;
      if (!responseData.success) {
        setUploadingImageError(responseData.message);
      } else {
        const avatar = responseData.data as { url: string };
        setUserDetails((prev) => {
          return {
            ...prev,
            avatar: avatar.url,
          };
        });
        setLogoForPreview(URL.createObjectURL(selectedFile));
      }
    } catch (error: any) {
      setUploadingImageError(error?.message || "Something went wrong");
    } finally {
      setUploadingImage(false);
    }
  }

  async function handleCreate() {
    try {
      setBtnText("Loading...");
      setIsBtnDisabled(true);
      const response: AxiosResponse = await userService.updateProfile(
        userDetails
      );
      const responseData = response.data as ResponseData;
      if (!responseData.success) {
        setGeneralError(responseData.message);
      } else {
        let route = "/workstations";  
        const acceptInvitationEncodedData = localStorage.getItem(
          "invitation_encoded_data"
        );
        if (acceptInvitationEncodedData) {
          route = `/accept-invitation/${acceptInvitationEncodedData}`;
        }
        navigate(route, { state: { isSignUp } });
      }
    } catch (error: any) {
      setGeneralError(error?.message || "Something went wrong");
    } finally {
      setIsBtnDisabled(false);
      setBtnText("Next");
    }
  }

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = e.target;
    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function deSelectLogo() {
    setLogoForPreview("");
    setUserDetails((prev) => ({
      ...prev,
      avatar: "",
    }));
  }

  return (
    <div
      className={cx(
        "d-flex flex-column bg-primary-color global-text-color p-5 justify-content-center align-items-center flex-column br-15 right-section-box")}
    >
      <div className="col-12 d-flex align-items-center text-center mb-2">
        <div
          className="col-2"
          onClick={() => navigate("/workstations")}
          role="button"
        ></div>
        <div className="col-8">
          <span className={cx("heading")}>Please add the following details</span>
        </div>
        <div className="col-2"></div>
      </div>
      {generalError && (
        <div className="mt-1 error-color text-center">{generalError}</div>
      )}
      {uploadingImage ? (
        <div
          style={{ height: "100px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="text-white">Uploading Logo...</div>
        </div>
      ) : (
        <div className="col-12 d-flex justify-content-center my-2">
          <div className="position-relative">
            <div
              role={!userDetails.avatar ? "button" : ""}
              onClick={() => {
                if (userDetails.avatar) return;
                imageRef?.current?.click();
              }}
              className={cx("avatar-container")}
            >
              {userDetails.avatar && (
                <div
                  className={cx("deselect-logo")}
                  role="button"
                  onClick={deSelectLogo}
                >
                  <img src={cross} alt="remove" />
                </div>
              )}
              <img
                src={logoForPreview || avatar}
                alt="avatar"
                className={cx("avatar-image")}
              />
              <input
                ref={imageRef}
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageSelect}
              />
            </div>
            {!userDetails.avatar && (
              <div role="button">
                <img
                  src={camera}
                  alt="camera"
                  className={cx("position-absolute", "camera")}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mt-1 text-danger text-center">{uploadingImageError}</div>
      <div className="col-12 col-xl-9 mt-3">
        <label className="ms-4" htmlFor="nickname">
          Full name <span className={cx("mandotory")}>*</span>
        </label>
        <div className="d-flex justify-content-center">
          <input
            type="text"
            id="nickname"
            name="nickname"
            value={userDetails.nickname}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleCreate();
              }
            }}
            className={cx(
              "bg-color-black input-width mt-2 py-3 px-4 w-100 text-white",
              "email"
            )}
            placeholder="Enter your full name"
          />
        </div>
      </div>
      <div className="col-12 col-xl-9 mt-4">
        <button
          type="button"
          className={cx("btn w-100 text-white py-3 py-xxxl-4")}
          onClick={handleCreate}
          disabled={isBtnDisabled}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default UserDetails;
