import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

// packages
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ResponseData } from "interfaces";
import { AxiosResponse } from "axios";
import { RootState } from "store";
import classNames from "classnames/bind";
// @ts-ignore
import OTPInput from "otp-input-react";

// css
import styles from "./verification.module.css";

// assets
import logo from "assets/mini-logo.svg";
import google from "assets/icons/google.svg";
import outlook from "assets/icons/outlook.svg";
import errorIcon from "assets/icons/error-icon.svg";

//services
import { userService } from "services";

const cx = classNames.bind(styles);

const VerificationCode: React.FC = (): JSX.Element => {
  const [verificationCode, setVerificationCode] = useState<string>("");

  const navigate: NavigateFunction = useNavigate();
  const { user } = useSelector((state: RootState) => state.user);

  const [isBtnDisabled, setIsBtnDisabled]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(true);
  const [error, setError]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("");
  const [nextBtnText, setNextBtnText]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("Next");
  const [sendAgainBtnText, setSendAgainBtnText]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("Send Again");
  const [sendAgainError, setSendAgainError]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("");

  function toggleBtnDisablity(): void {
    setIsBtnDisabled((prev: boolean): boolean => !prev);
  }

  useEffect(() => {
    if (verificationCode.length < 6) return;
    handleNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode]);

  async function handleNext(): Promise<void> {
    try {
      // toggleBtnDisablity();
      setNextBtnText("Loading...");
      const response: AxiosResponse = await userService.verifyEmail(
        `${user?.id}/${verificationCode}`
      );
      const responseData = response.data as ResponseData;
      if (!responseData.success) {
        toggleBtnDisablity();
        setError(responseData.message);
      } else {
        navigate("/setup-password", {
          state: { verificationCode, isSignUp: true },
        });
      }
    } catch (error: any) {
      toggleBtnDisablity();
      setError(error?.message || "Something went wrong");
    } finally {
      // toggleBtnDisablity();
      setNextBtnText("Next");
    }
  }

  async function handleSendAgain() {
    try {
      const { email } = user;
      if (!email) return;
      setVerificationCode("");
      setSendAgainBtnText("Sending...");
      toggleBtnDisablity();
      const response: AxiosResponse = await userService.resendVerificationCode({
        email,
      });
      const responseData = response.data as ResponseData;
      setSendAgainBtnText(responseData.message);
    } catch (error: any) {
      setSendAgainError(error?.message || "Something went wrong");
    } finally {
      toggleBtnDisablity();
    }
  }

  return (
    <div
      className={cx("row bg-primary-color p-1 p-md-3 p-xxl-5 global-text-color text-center justify-content-center align-items-center flex-column br-15 right-section-box")}
    >
      <div className="col-12 primary-heading">
        <div className="d-flex align-items-center justify-content-center">
          <span>Sign Up for</span>
          <img className="mx-2 hookzoffice-card-logo" src={logo} alt="hookzOffice" />{" "}
        </div>
        <span>free plan, It's quick.</span>
      </div>
      <div className={cx("col-12 mt-3", "codeExpiresText")}>
        <span>Check your code for a 6 digit code</span>
      </div>
      <div className={cx("col-12", "codeExpiresText")}>
        <span>The code expires shortly, enter soon.</span>
      </div>
      {error && (
        <div className="my-2 error-color d-flex align-items-center justify-content-center">
          <img src={errorIcon} alt="error" />
          <span className="ms-1">{error}</span>
        </div>
      )}
      <div className="col-12 d-flex justify-content-center mt-3">
        <OTPInput
          value={verificationCode}
          onChange={setVerificationCode}
          autoFocus
          OTPLength={6}
          otpType="alphanumeric"
          // disabled={isBtnDisabled}
          inputStyles={{
            background: "#1A1A1A",
            border: "none",
            borderRadius: 5,
            // marginTop: 10,
            height: 60,
            width: 60,
            color: "#ffff",
            fontSize: "2rem"
          }}
        />
      </div>
      <div className="col-12 col-xl-9 mt-3">
        <button
          type="button"
          className="btn btn-lg mt-3 py-3 w-100 text-white"
          onClick={handleNext}
          disabled={isBtnDisabled}
        >
          {nextBtnText}
        </button>
      </div>
      <div className="col-12 d-flex justify-content-around">
        <div
          className="global-font-size mt-2 link"
          role="button"
          onClick={handleSendAgain}
        >
          {sendAgainBtnText}
        </div>
        <div
          className="global-font-size mt-2 link"
          role="button"
          onClick={() => navigate("/sign-up")}
        >
          Change Email
        </div>
      </div>
      <div className="col-12">
        <div className="ms-4 mt-2 text-danger">{sendAgainError}</div>
      </div>
      <div className="col-12 mt-5">
        <div>Open to get the code</div>
        <div className="mt-3">
          <img src={google} alt="google" className="me-1" />
          <img src={outlook} alt="microsoft" className="ms-1" />
        </div>
      </div>
    </div>
  );
};

export default VerificationCode;
