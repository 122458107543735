import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import classNames from "classnames/bind";

import styles from "./acceptInvitation.module.css";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { workstationsService } from "services";
import { ResponseData, Workstation } from "interfaces";
import { saveSessionToFirebaseInServer } from "services/apim.service";
import { getWebChatUrl } from "helpers";
import { AnyAction } from "redux";
import { setAcceptInvitationEncodedData } from "store/slices/user";

// assets
import errorIcon from "assets/icons/error-icon.svg";

const cx = classNames.bind(styles);

interface InvitationActionData {
  id: string;
  email?: string;
  code: string;
  action: string;
  company_id: number | string;
  invited_by: string;
  time?: string;
}

const { REACT_APP_HOST_NAME } = process.env;

const AcceptInvitation = () => {
  const navigate: NavigateFunction = useNavigate();
  const { encodedData } = useParams();
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch: Dispatch<AnyAction> = useDispatch();

  const [isLoadingText, setIsLoadingText]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("Loading...");
  const [errorMsg, setErrorMsg]: [string, Dispatch<SetStateAction<string>>] =
    useState("");

  useEffect(() => {
    try {
      const token: string | null = localStorage.getItem("token");
      if (!token) {
        localStorage.setItem("invitation_encoded_data", encodedData!);
        dispatch(setAcceptInvitationEncodedData(encodedData!));
        navigate("/");
      } else {
        let dataToBeParsed = encodedData;
        if (!dataToBeParsed) {
          dataToBeParsed =
            localStorage.getItem("invitation_encoded_data") || undefined;
        }
        if (!dataToBeParsed) {
          setErrorMsg(`You are not invited`);
          setIsLoadingText("");
        } else {
          const { Company, invitation } = JSON.parse(atob(dataToBeParsed!)) as {
            Company: Workstation;
            invitation: InvitationActionData;
          };
          const { id, invited_by, company_id, time, email } = invitation;
          if (email !== user.email) {
            setErrorMsg(`You are not invited to ${Company.name}`);
            setIsLoadingText("");
          } else {
            inviteToHub(
              {
                id,
                invited_by,
                company_id,
                code: time!.toString(),
                action: "1",
              },
              Company
            );
          }
        }
      }
    } catch (error: any) {
      setErrorMsg(error?.message || "Something went wrong");
      setIsLoadingText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function inviteToHub(invitationData: object, hubData: Workstation) {
    try {
      setIsLoadingText("Checking your invitation...");
      const { invitationAction } = workstationsService;
      const response: AxiosResponse = await invitationAction(invitationData);
      const responseData: ResponseData = response.data;
      if (responseData.success) {
        loginToWebchat(responseData.data as { company_id: number }, hubData);
      } else {
        setErrorMsg(responseData.message);
        setIsLoadingText("");
      }
    } catch (error: any) {
      setErrorMsg(error?.message || "Something went wrong");
      setIsLoadingText("");
    }
  }

  async function loginToWebchat(
    data: { company_id: number | string },
    hubData: Workstation
  ) {
    setIsLoadingText(`Joining ${hubData.name}`);
    const { company_id } = data;
    const { id, email, nickname, token } = user;
    const response = (await saveSessionToFirebaseInServer({
      token,
      jid: `${id}@${REACT_APP_HOST_NAME}`,
      userId: id,
      email,
      mobile: email,
      profile: {
        nickName: nickname,
        imageUrl: "",
        privacySettings: {
          last: 1,
          avatar: 1,
        },
      },
      company_id,
    })) as ResponseData;
    if (response.success) {
      const sessionId = (response.data as { session_id: string }).session_id;
      window.location.href = getWebChatUrl(sessionId);
    } else {
      setErrorMsg(response.message);
      setIsLoadingText("");
    }
  }

  return (
    <div
      className={cx(
        "d-flex flex-column justify-content-center align-items-center bg-color-black h-100 w-100 px-1 px-md-5",
        "main"
      )}
    >
      {isLoadingText && (
        <div className="global-text-color">{isLoadingText}</div>
      )}
      {errorMsg && (
        <div className="error-color d-flex align-items-center">
          <img src={errorIcon} alt="error" />{" "}
          <span className="ms-1">{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default AcceptInvitation;
