import React, { useEffect } from "react";

import logo from "assets/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";

const LeftSection: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");

  function handleClick() {
    navigate("/");
  }

  // useEffect(() => {
  //   // debugger;
  //   const publicRoutes = ['/', "/sign-up", "/sign-in", "/verification-code", "/password", "/setup-password", "/forgot-password"];
  //   if(token && publicRoutes.includes(location.pathname)) {
  //     navigate('/workstations');
  //   } else {
  //     navigate('/');
  //   }
  // }, [location]);

  return (
    <div className="col-md-6 left-section d-none d-lg-flex align-items-center justify-content-center">
      <div className="w-50" role="button" onClick={handleClick}>
        <img className="w-100" src={logo} alt="HookzOffice" />
      </div>
    </div>
  );
};

export default LeftSection;
