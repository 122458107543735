import React from 'react'
import classNames from "classnames/bind";

import styles from "./logout.module.css";
import { logout } from 'helpers';

const cx = classNames.bind(styles);

const Logout = () => {
  return (
    <div role='button' onClick={logout} className={cx('logout')}>Logout</div>
  )
}

export default Logout