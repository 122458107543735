import React, {
  ChangeEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

//css
import styles from "./resetPassword.module.css";

// packages
import classNames from "classnames/bind";
import { AxiosResponse } from "axios";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

// assets
import openEye from "assets/icons/open-eye.svg";
import closedEye from "assets/icons/closed-eye.svg";
import errorIcon from "assets/icons/error-icon.svg";

import { GlobalConfig } from "config";
import { ResponseData } from "interfaces";
import { userService } from "services";

const cx = classNames.bind(styles);

interface Passwords {
  code: string;
  email: string;
  password: string;
  cpassword: string;
}

interface DecodedData {
  code: string;
  email: string;
}

const { passwordRegex } = GlobalConfig;

const ResetPassword = () => {
  const confirmPasswordRef: RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const navigate: NavigateFunction = useNavigate();

  const { encodedData } = useParams();

  const [values, setValues]: [Passwords, Dispatch<SetStateAction<Passwords>>] =
    useState<Passwords>({
      code: "",
      email: "",
      password: "",
      cpassword: "",
    });
  const [isBtnDisabled, setIsBtnDisabled]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    useState<string>("");
  const [btnText, setBtnText]: [string, (btnText: string) => void] =
    useState<string>("Reset");
  const [newPasswordError, setPasswordError]: [
    string,
    (password: string) => void
  ] = useState<string>("");
  const [showPassword, setShowPassword]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  function togglePasswordVisibility(): void {
    setShowPassword((prev: boolean): boolean => !prev);
  }

  function toggleConfirmPasswordVisibility(): void {
    setShowConfirmPassword((prev: boolean): boolean => !prev);
  }

  useEffect(() => {
    try {
      const { code, email } = JSON.parse(atob(encodedData!)) as DecodedData;
      setValues((prev) => {
        return {
          ...prev,
          code,
          email,
        };
      });
    } catch (error) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { password, cpassword } = values;
    if (password && !password.match(passwordRegex)) {
      setPasswordError("Weak password");
      return;
    } else {
      setPasswordError("");
    }

    if (cpassword && password !== cpassword) {
      setError("Passwords didn't match");
      setIsBtnDisabled(true);
      return;
    } else {
      setError("");
    }
    if (cpassword && password === cpassword) {
      setIsBtnDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    setValues((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  async function handleReset(): Promise<void> {
    try {
      if (error || newPasswordError) return;
      setBtnText("Loading...");
      setIsBtnDisabled(true);
      const response: AxiosResponse = await userService.resetPassword(values);
      const responseData: ResponseData = response.data;
      if (!responseData.success) {
        setError(responseData.message);
        return;
      } else {
        navigate("/sign-in");
      }
    } catch (error: any) {
      setError(error?.message || "Something went wrong");
    } finally {
      setBtnText("Reset");
    }
  }

  return (
    <div
      className={cx(
        "d-flex justify-content-center align-items-center bg-color-black bg-primary-color h-100 w-100 px-1 px-md-5",
        "main"
      )}
    >
      <div
        className={cx(
          "d-flex flex-column align-items-center bg-primary-color w-100",
          "box"
        )}
      >
        <div className="row w-100 py-5">
          <div className="col-md-6 col-sm-12 text-white mb-0 heading margin-auto text-align-center">
            <div className="d-flex justify-content-center">Reset password</div>
          </div>
        </div>
        {error && (
          <div className="ms-4 mb-4 error-color">
            <img src={errorIcon} alt="error" /> <span>{error}</span>
          </div>
        )}
        <div className="d-flex flex-column align-items-center text-white w-100">
          <div className="row text-white w-100 my-2">
            <div className="col-md-6 col-sm-12 margin-auto">
              <label className="ms-4" htmlFor="password">
                New Password
              </label>
              <div className="d-flex align-items-center input-field bg-color-grey input-width px-4 mt-2 w-100">
                <input
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="bg-color-black py-3 text-white w-100"
                  placeholder="New Password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (confirmPasswordRef.current) {
                        confirmPasswordRef.current.focus();
                      }
                    }
                  }}
                />
                <div role="button" onClick={togglePasswordVisibility}>
                  <img
                    src={showPassword ? openEye : closedEye}
                    alt={showPassword ? "hide" : "show"}
                    className="password-open-close-eye"
                  />
                </div>
              </div>
              <div className="ms-4 mt-2 text-danger">{newPasswordError}</div>
            </div>
          </div>
          <div className="row text-white w-100 my-2">
            <div className="col-md-6 col-sm-12 margin-auto">
              <label className="ms-4" htmlFor="confirm-password">
                Confirm Password
              </label>
              <div className="d-flex align-items-center input-field bg-color-grey input-width px-4 mt-2 w-100">
                <input
                  ref={confirmPasswordRef}
                  name="cpassword"
                  value={values.cpassword}
                  onChange={handleChange}
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  className="bg-color-black py-3 text-white w-100"
                  placeholder="Confirm Password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleReset();
                    }
                  }}
                />
                <div role="button" onClick={toggleConfirmPasswordVisibility}>
                  <img
                    src={showConfirmPassword ? openEye : closedEye}
                    alt={showConfirmPassword ? "hide" : "show"}
                    className="password-open-close-eye"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 w-100 mb-5">
          <div className="col-md-6 col-sm-12 margin-auto">
            <button
              type="button"
              className={cx(
                "btn btn-lg text-white mt-3 py-3 w-100 border-none",
                "btn"
              )}
              onClick={handleReset}
              disabled={isBtnDisabled}
            >
              {btnText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
