import React, { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface PublicRouteProps {
  children: React.ReactElement;
}

export default function PublicRoute({
  children,
}: PublicRouteProps): React.ReactElement | null {
  const navigate : NavigateFunction = useNavigate();
  const token: string | null = localStorage.getItem("token");

  useEffect(() => {
    if(token) {
      navigate('/workstations');
    }
  }, [token, navigate]);

  return children as React.ReactElement;
}
