import React, {
  ChangeEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

// packages
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import classNames from "classnames/bind";

// assets
import avatar from "assets/icons/avatar.svg";
import camera from "assets/icons/camera.svg";
import back from "assets/icons/back-arrow.svg";
import cross from "assets/icons/cross.svg";

// css
import styles from "./workstationRegistration.module.css";
import { AxiosResponse } from "axios";
import { workstationsService } from "services";
import {
  ResponseData,
  UploadLogoResponseData,
  Workstation,
} from "interfaces";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { getBgColorForHub, getRandomString } from "helpers";
import { RootState } from "store";
import { useSelector } from "react-redux";

const cx = classNames.bind(styles);

interface WorkstationDetails {
  person_contact: string;
  name: string;
  email: string;
  website_link: string;
  phone_code: string;
  phone_number: string;
  logo: string;
  readonly code: string;
  readonly color: string;
}

const WorkstationRegistration: React.FC = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const { user } = useSelector((state: RootState) => state.user);
  const imageRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const [workstationDetails, setWorkstationDetails] =
    useState<WorkstationDetails>({
      person_contact: "",
      name: "",
      email: user.email,
      website_link: "",
      phone_code: "",
      phone_number: "",
      logo: "",
      code: getRandomString(8),
      color: getBgColorForHub()
    });
  const [nameError, setNameError]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("");
  const [companyNameError, setCompanyNameError]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("");
  const [websiteLinkError, setWebsiteLinkError]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("");
  const [generalError, setGeneralError]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("");
  const [btnText, setBtnText]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("Next");
  const [isBtnDisabled, setIsBtnDisabled]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(true);
  const [uploadingImage, setUploadingImage]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [uploadingImageError, setUploadingImageError]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [logoForPreview, setLogoForPreview] = useState("");

  useEffect(() => {
    if (nameError) {
      if (workstationDetails.person_contact) {
        setNameError("");
      }
    }
    if (companyNameError) {
      if (workstationDetails.name) {
        setCompanyNameError("");
      }
    }
    if (websiteLinkError) {
      if (workstationDetails.website_link) {
        setWebsiteLinkError("");
      }
    }

    if(workstationDetails.name && workstationDetails.person_contact){
      setIsBtnDisabled(false)
    }else {
      setIsBtnDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workstationDetails]);

  function handleImageSelect(e: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile: File | null = e.target.files?.[0] || null;
    if (!selectedFile) {
      setGeneralError("Error while selecting a file.");
    } else {
      handleUploadLogo(selectedFile);
    }
  }

  async function handleUploadLogo(selectedFile: File) {
    try {
      // setBtnText("Loading...");
      const formData = new FormData();
      formData.append("image", selectedFile);
      setUploadingImage(true);
      const response: AxiosResponse<ResponseData> =
        await workstationsService.uploadImageAndGetLink(formData);

      const responseData =
        response.data as UploadLogoResponseData;
      if (!responseData.success) {
        setUploadingImageError(responseData.message);
      } else {
        const logo = responseData.data as { url: string };
        setWorkstationDetails((prev) => {
          return {
            ...prev,
            logo: logo.url,
          };
        });
        setLogoForPreview(URL.createObjectURL(selectedFile));
      }
    } catch (error: any) {
      setUploadingImageError(error?.message || "Something went wrong");
    } finally {
      setUploadingImage(false);
    }
  }

  async function handleCreate() {
    try {
      let isError = false;
      if (!workstationDetails.person_contact) {
        setNameError("Name is required");
        isError = true;
      }
      if (!workstationDetails.name) {
        setCompanyNameError("Company name is required");
        isError = true;
      }
      if (workstationDetails.website_link) {
        const urlRegex =
          /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
        if (!workstationDetails.website_link?.match(urlRegex)) {
          setWebsiteLinkError("Invalid Link");
          isError = true;
        }
      }
      if (isError) {
        return;
      }
      let phoneNumberArray = phoneNumber.split(" ");
      const country_code = phoneNumberArray[0];
      phoneNumberArray.shift();
      let phone_number = "";
      phoneNumberArray.map((number) => {
        phone_number += number;
      });
      workstationDetails.phone_code = country_code;
      workstationDetails.phone_number = phone_number;
      setBtnText("Loading...");
      setIsBtnDisabled(true);
      const response: AxiosResponse =
        await workstationsService.workstationRegistration(workstationDetails);
      const responseData = response.data as ResponseData;
      if (!responseData.success) {
        setGeneralError(responseData.message);
      } else {
        navigate("/subscribe", {
          state: { workstation: responseData.data as Workstation },
        });
      }
    } catch (error: any) {
      setGeneralError(error?.message || "Something went wrong");
    } finally {
      setIsBtnDisabled(false);
      setBtnText("Next");
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setWorkstationDetails((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  function handlePhoneNumber(formattedValue: string) {
    setPhoneNumber(formattedValue);
  }

  function deSelectLogo() {
    setLogoForPreview("");
    setWorkstationDetails((prev) => ({
      ...prev,
      logo: "",
    }));
  }

  return (
    <div
      className={cx(
        "d-flex flex-column bg-primary-color global-text-color p-1 p-md-3 p-xxl-5 br-15 right-section-box justify-content-center align-items-center flex-column",
      )}
    >
      <div className="col-12 d-flex align-items-center text-center mb-2">
        <div
          className="col-2"
          onClick={() => navigate("/workstations")}
          role="button"
        >
          <img src={back} alt="back" />
        </div>
        <div className="col-8">
          <span className="secondary-heading">
            Create your hub by adding the following details
          </span>
        </div>
        <div className="col-2"></div>
      </div>
      {generalError && (
        <div className="mt-1 text-danger text-center">{generalError}</div>
      )}
      {uploadingImage ? (
        <div
          style={{ height: "100px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="text-white">Uploading Logo...</div>
        </div>
      ) : (
        <div className="col-12 d-flex justify-content-center my-2">
          <div className="position-relative">
            <div
              role={!workstationDetails.logo ? "button" : ""}
              onClick={() => {
                if (workstationDetails.logo) return;
                imageRef?.current?.click();
              }}
              className={cx("avatar-container")}
            >
              {workstationDetails.logo && (
                <div
                  className={cx("deselect-logo")}
                  role="button"
                  onClick={deSelectLogo}
                >
                  <img src={cross} alt="remove" />
                </div>
              )}
              <img
                src={logoForPreview || avatar}
                alt="avatar"
                className={cx("avatar-image")}
              />
              <input
                ref={imageRef}
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageSelect}
              />
            </div>
            {!workstationDetails.logo && (
              <div role="button">
                <img
                  src={camera}
                  alt="camera"
                  className={cx("position-absolute", "camera")}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mt-1 text-danger text-center">{uploadingImageError}</div>
      <div className="col-12 col-xl-9">
        <label className="ms-4" htmlFor="email">
          Name <span className={cx("mandotory")}>*</span>
        </label>
        <div className="d-flex justify-content-center">
          <input
            type="text"
            id="email"
            name="person_contact"
            value={workstationDetails.person_contact}
            onChange={handleChange}
            className={cx(
              "bg-color-black input-width mt-2 py-3 px-4 w-100 text-white",
              "email"
            )}
            placeholder="Your Full Name"
          />
        </div>
        <div className="ms-4 mt-2 text-danger">{nameError}</div>
      </div>
      <div className="col-12 col-xl-9 mt-2">
        <label className="ms-4" htmlFor="email">
          Company's Name <span className={cx("mandotory")}>*</span>
        </label>
        <div className="d-flex justify-content-center">
          <input
            type="text"
            id="email"
            name="name"
            value={workstationDetails.name}
            onChange={handleChange}
            className={cx(
              "bg-color-black input-width mt-2 py-3 px-4 w-100 text-white",
              "email"
            )}
            placeholder="Your registered company name"
          />
        </div>
        <div className="ms-4 mt-2 text-danger">{companyNameError}</div>
      </div>
      <div className="col-12 col-xl-9 mt-2">
        <label className="ms-4" htmlFor="email">
          Website
        </label>
        <div className="d-flex justify-content-center">
          <input
            type="text"
            id="email"
            name="website_link"
            onChange={handleChange}
            className={cx(
              "bg-color-black input-width mt-2 py-3 px-4 w-100 text-white",
              "email"
            )}
            placeholder="Website URL"
          />
        </div>
        <div className="ms-4 mt-2 text-danger">{websiteLinkError}</div>
      </div>
      <div className="col-12 col-xl-9 mt-2">
        <label className="ms-4" htmlFor="email">
          Mobile Number
        </label>
        <div className={cx("d-flex justify-content-center", "mobile-input")}>
          <PhoneInput
            country={"ae"}
            enableSearch
            value={phoneNumber}
            onChange={(_value, _country, _e, formattedValue) =>
              handlePhoneNumber(formattedValue)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleCreate();
              }
            }}
          />
        </div>
      </div>
      <div className="col-12 col-xl-9 mt-5">
        <button
          type="button"
          className={cx("btn w-100 text-white py-3 py-xxxl-4")}
          onClick={handleCreate}
          disabled={isBtnDisabled}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default WorkstationRegistration;
