import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

// packages
import classNames from "classnames/bind";
import {
  Link,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";

// assets
import logo from "assets/subscribe-to-hookzoffice-logo.svg";
import blueRightArrow from "assets/icons/blue-right-arrow.svg";
import rightArrow from "assets/icons/right-arrow.svg";
import errorIcon from "assets/icons/error-icon.svg";

// css
import styles from "./subscribe.module.css";
import { plansService } from "services";
import { AxiosResponse } from "axios";
import { ResponseData } from "interfaces";
import { Plan } from "./interfaces";

const cx = classNames.bind(styles);

const Subscribe = () => {
  const navigate: NavigateFunction = useNavigate();
  const { state } = useLocation();

  const [selectedPlan, setSelectedPlan]: [
    number,
    Dispatch<SetStateAction<number>>
  ] = useState<number>(1);
  const [error, setError]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("");
  const [isLoading, setIsLoading]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(true);
  const [plans, setPlans]: [Plan[], Dispatch<SetStateAction<Plan[]>>] =
    useState<Plan[]>([]);
  const [hoveredLink, setHoveredLink] = useState<number | null>(null);

  useEffect(() => {
    getPlans();
  }, []);

  async function getPlans(): Promise<void> {
    try {
      setIsLoading(true);
      const response: AxiosResponse = await plansService.getPlansList();
      const responseData: ResponseData = response.data as ResponseData;
      if (!responseData.success) {
        setError(responseData.message);
      } else {
        let plansData = responseData.data as Plan[];
        plansData = [
          {
            id: 1,
            title: "Basic",
            subtitle: "Free",
            about: "No credit card. No commitment",
          },
          {
            id: 2,
            title: "Pro",
            subtitle: "$5/user/Month",  
            about: "$50/user/Year",
          },
          {
            id: 3,
            title: "Business",
            subtitle: "$8/user/Month",
            about: "$80/user/Year",
          }
        ];
        setPlans(plansData);
      }
    } catch (error: any) {
      setError(error?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }

  function handleGetStarted(): void {
    if (!selectedPlan) {
      setError("No plan selected");
    } else {
      navigate("/invite-coworkers", { state });
    }
  }

  function handleMouseEnter(linkId: number): void {
    setHoveredLink(linkId);
  }

  function handleMouseLeave(): void {
    setHoveredLink(null);
  }

  return (
    <div
      className={cx(
        "row d-flex justify-content-center align-items-center bg-color-black h-100 px-1 px-md-5"
      )}
    >
      <div
        className={cx(
          "col-xs-12 col-md-10 d-flex flex-column align-items-center",
          "custom-col"
        )}
      >
        <div className="row w-100">
          <div className="col-12 text-white mb-0">
              <img className="mx-2 w-50 w-xxl-25" src={logo} alt="hookzOffice" />{" "}
          </div>
        </div>
        <div
          className={cx(
            "d-flex flex-column align-items-center w-100 text-white mt-3",
            "plans-container",
            { "justify-content-center": isLoading }
          )}
        >
          {isLoading ? (
            <div className="text-white">Fetching plans...</div>
          ) : plans?.length ? (
            plans.map((plan) => (
              <div
                key={plan.id}
                className={cx("row text-white w-100 my-1", "br-15", {
                  "bg-secondary-color padding-1px": selectedPlan === plan.id,
                })}
                role="button"
                onClick={() => {
                  setSelectedPlan(plan.id);
                  setError("");
                }}
              >
                <div className="col-12 p-4 br-15 bg-primary-color">
                  <div className="row justify-content-end">
                    <div className="col-12 col-md-8 d-flex flex-column mb-3 mb-md-0">
                      <div className="d-flex align-items-center">
                        <div className="heading2 text-secondary-color">
                          {plan.title}
                        </div>
                        <div className="ms-2">({plan.subtitle})</div>
                      </div>
                      <div className="global-font-size">{plan.about}</div>
                    </div>
                    <div
                      className="col-12 col-md-4 d-flex align-items-center justify-content-end"
                      onMouseEnter={() => handleMouseEnter(plan.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Link
                        to="/learn-more"
                        className={
                          hoveredLink === plan.id ? "active-link" : "link"
                        }
                      >
                        Learn more
                      </Link>
                      <img
                        src={
                          hoveredLink === plan.id ? blueRightArrow : rightArrow
                        }
                        alt="learn-more"
                        className="ms-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-white">No record found</div>
          )}
        </div>
        {error && (
          <div className="mt-4 error-color">
            <img src={errorIcon} alt="error" /> <span>{error}</span>
            {error}
          </div>
        )}
        <div className="row mt-4 w-100">
          <div className="col-md-8 col-sm-12 margin-auto">
            <button
              type="button"
              className={cx("btn btn-lg text-white mt-3 py-3 w-100", "btn")}
              onClick={handleGetStarted}
            >
              Get started
            </button>
          </div>
        </div>
        <div className="row global-font-size my-4">
          <Link
            to="/terms-and-conditions"
            target="_blank"
            className="global-font-size link"
          >
            Terms & Conditions{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
