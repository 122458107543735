import axios, { AxiosError, AxiosHeaders, AxiosResponse } from "axios";
import { GlobalConfig } from "config";

export function logout(): void {
  localStorage.clear();
  window.location.href = "/";
}

export function handleResponse(response: AxiosResponse): AxiosResponse {
  const { data, status } = response;
  if (status !== 200) {
    if (status === 401) {
      // auto logout if 401 response returned from api
      logout();
    }

    // If the error response contains a message, throw an error with that message
    if (data && typeof data === "object" && "message" in data) {
      throw new Error(data.message);
    } else {
      // If the error response doesn't have a specific message, throw a generic error
      throw new Error("Unknown error occurred.");
    }
  } else {
    return response;
  }
}

export function handleError(error: AxiosError): AxiosResponse {
  if (axios.isAxiosError(error)) {
    const response = error.response;
    if (response) {
      const { data, status } = response;
      if (status === 401) {
        // auto logout if 401 response returned from api
        logout();
      } else if (status === 404) {
        // Handle the 404 error as needed
        // For example, you can throw a specific error or return a custom response
        throw new Error("Resource not found.");
      }

      // If the error response contains a message, throw an error with that message
      if (data && typeof data === "object" && "message" in data) {
        throw new Error(data.message as string); // Explicitly convert data to a string
      } else {
        // If the error response doesn't have a specific message, throw a generic error
        throw new Error("Unknown error occurred.");
      }
    } else {
      // Handle network errors (e.g., no response from the server)
      throw new Error("Network error occurred.");
    }
  } else {
    // Handle non-axios errors (e.g., thrown manually using `throw new Error()`)
    throw error;
  }
}

export function isStringEmpty(str: string | null | undefined): boolean {
  try {
    if (!str) return true;
    return !str.trim().length;
  } catch (error) {
    return true;
  }
}

export function calculatePages(count: number, items_per_page: number): number {
  let pages = Math.floor(count / items_per_page);
  if (count % items_per_page) pages += 1;
  return pages;
}

export function getRandomString(length: number): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

export function authHeader(): AxiosHeaders {
  const token: string | null = localStorage.getItem("token");
  const headers = new AxiosHeaders({
    "Content-Type": "application/json",
    Accept: "application/json",
  });
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
}

export function formatDate(dateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}

export function getBgColorForHub() {
  const hexCodeColors = ["#3EA77C", "#193031", "#FF5758", "#089CAB", "#D9D9D9", "#089CAC", "#06717A", "#38ADF8", "#707070", "#007C74", "#07647F", "#17B9D9", "#10C0B2", "#13DF22", "#21D483", "#3BAEF2", "#E025FA", "#FE3618", "#00000029", "#8B8C8C", "#3FA77C"];
  
  const randomIndex = Math.floor(Math.random() * hexCodeColors.length);
  return hexCodeColors[randomIndex];
}

export function getWebChatUrl(sessionId: string){
  if(!sessionId) return '';
  return `${GlobalConfig.WEB_CHAT_URL}${sessionId}`
}
