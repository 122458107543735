import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import styles from "./forgotPassword.module.css";

import classNames from "classnames/bind";
import { APIResponse } from "../InviteCoworkers/interfaces";
import { GlobalConfig } from "config";
import { userService } from "services";
import { AxiosResponse } from "axios";
import { ResponseData } from "interfaces";

const cx = classNames.bind(styles);

const { emailRegex } = GlobalConfig;

const ForgotPassword = () => {
  const isMounted = useRef<Boolean>(false);
  const [email, setEmail]: [string, (btnText: string) => void] =
    useState<string>("");
  const [isBtnDisabled, setIsBtnDisabled]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(true);
  const [emailError, setEmailError]: [string, (emailError: string) => void] =
    useState<string>("");
  const [btnText, setBtnText]: [string, (btnText: string) => void] =
    useState<string>("Send");
  const [apiResponse, setApiResponse]: [
    APIResponse,
    Dispatch<SetStateAction<APIResponse>>
  ] = useState<APIResponse>({ message: "", success: null });

  useEffect(() => {
    if (isMounted.current) {
      setEmailError("");
      if (!email?.match(emailRegex) && email !== "") {
        setEmailError("Invalid email");
        setIsBtnDisabled(true);
      } else {
        setIsBtnDisabled(false);
      }
    }
    isMounted.current = true;
  }, [email]);

  async function handleSend(): Promise<void> {
    try {
      setBtnText("Loading...");
      setIsBtnDisabled(true);
      const response: AxiosResponse = await userService.forgotPassword({
        email,
      });
      const responseData: ResponseData = response.data;
      const { message, success } = responseData;
      setApiResponse({ message, success });
    } catch (error: any) {
      setApiResponse({
        message: error?.message || "Something went wrong",
        success: 0,
      });
    } finally {
      setEmail("");
      setBtnText("Send");
      setIsBtnDisabled(false);
    }
  }

  return (
    <div
      className={cx(
        "row bg-primary-color p-1 p-md-3 p-xxl-5 global-text-color justify-content-center align-items-center flex-column br-15 right-section-box"
      )}
    >
      <div className="row w-100 py-5">
        <div className="col-12 text-white mb-0 heading margin-auto text-align-center">
          <div className="d-flex justify-content-center secondary-heading">
            Forgot password
          </div>
        </div>
      </div>
      <div
        className={cx(
          "mb-4 text-center",
          apiResponse.success ? "text-success" : "text-danger"
        )}
      >
        {apiResponse.message}
      </div>
      <div className="col-12 col-xl-9">
        <label className="ms-4" htmlFor="email">
          Email Address
        </label>
        <div className="d-flex justify-content-center">
          <input
            type="text"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && !emailError && handleSend()}
            className={cx(
              "bg-color-black input-width mt-2 py-3 px-4 w-100 text-white",
              "email"
            )}
            placeholder="Email your email address"
          />
        </div>
        <div className="ms-4 mt-2 text-danger">{emailError}</div>
      </div>
      <div className="col-12 col-xl-9 mt-4">
        <button
          type="button"
          className={cx("btn w-100 text-white py-3 py-xxxl-4")}
          onClick={handleSend}
          disabled={isBtnDisabled}
        >
          {btnText}
        </button>
      </div>
      <div className="row global-font-size text-center my-2 mb-5">
        <Link to="/sign-in" className="link">
          Sign up
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
