import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import styles from "./workstationsList.module.css";

// assets
import rightArrow from "assets/icons/right-arrow.svg";
import errorIcon from "assets/icons/error-icon.svg";
import searchIcon from "assets/icons/search.svg";

// packages
import classNames from "classnames/bind";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  ResponseData,
  Workstation,
  WorkstationsResponseData,
} from "interfaces";
import { AxiosResponse } from "axios";

import { getWebChatUrl } from "helpers";
import { saveSessionToFirebaseInServer } from "services/apim.service";

// services
import { workstationsService } from "services";
import Logout from "Components/Logout/Logout";

const cx = classNames.bind(styles);

const { REACT_APP_HOST_NAME } = process.env;

const WorkstationsList = () => {
  const { state } = useLocation();
  const isSignUp = state?.isSignUp;
  const navigate: NavigateFunction = useNavigate();
  const { user } = useSelector((state: RootState) => state.user);

  const [workStations, setWorkStations]: [
    Workstation[],
    Dispatch<SetStateAction<Workstation[]>>
  ] = useState<Workstation[]>([]);
  const [filteredWorkStations, setFilteredWorkStations]: [
    Workstation[],
    Dispatch<SetStateAction<Workstation[]>>
  ] = useState<Workstation[]>([]);
  const [isLoading, setIsLoading]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(true);
  const [selectedWorkstation, setSelectedWorkstation]: [
    Workstation,
    Dispatch<SetStateAction<Workstation>>
  ] = useState<Workstation>({
    id: null,
    logo: null,
    name: null,
    website_link: null,
  });
  const [btnText, setBtnText]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("Create a hub");
  const [isBtnDisabled, setIsBtnDisabled]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [errorMsg, setErrorMsg]: [string, Dispatch<SetStateAction<string>>] =
    useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (isSignUp) return;
    getWorkstationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedWorkstation.id) {
      setBtnText("Join");
    } else {
      setBtnText("Create a hub");
    }
  }, [selectedWorkstation]);

  useEffect(() => {
    if (!search) {
      setFilteredWorkStations(workStations);
    } else {
      const filtered_workstations = workStations.filter((workStation) => {
        const { name, person_contact, phone_code, phone_number, website_link } =
          workStation;
        const lowerCaseSearched = search.toLowerCase();
        if (
          name?.toLowerCase().includes(lowerCaseSearched) ||
          person_contact?.toLowerCase().includes(lowerCaseSearched) ||
          phone_code?.toLowerCase().includes(lowerCaseSearched) ||
          phone_number?.toLowerCase().includes(lowerCaseSearched) ||
          website_link?.toLowerCase().includes(lowerCaseSearched)
        ) {
          return workStation;
        }
      });
      setFilteredWorkStations(filtered_workstations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  async function getWorkstationsList() {
    try {
      setIsLoading(true);
      const response: AxiosResponse<ResponseData> =
        await workstationsService.getWorkstationsList({
          limit: 100000,
          page: 1,
        });
      const responseData = response.data as WorkstationsResponseData;
      const workstationsData = responseData.data;
      if (responseData.success) {
        setWorkStations(workstationsData.rows);
        setFilteredWorkStations(workstationsData.rows);
      } else {
        setErrorMsg(responseData.message);
      }
    } catch (error: any) {
      setErrorMsg(error?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleNavigateToWorkstation(
    workstation: Workstation
  ): Promise<void> {
    try {
      setBtnText("Joining");
      setIsBtnDisabled(true);
      const company_id = workstation?.id;
      const { id, email, nickname, token } = user;
      const response = (await saveSessionToFirebaseInServer({
        token,
        jid: `${id}@${REACT_APP_HOST_NAME}`,
        userId: id,
        email,
        mobile: email,
        profile: {
          nickName: nickname,
          imageUrl: "",
          privacySettings: {
            last: 1,
            avatar: 1,
          },
        },
        company_id,
      })) as ResponseData;
      if (response.success) {
        const sessionId = (response.data as { session_id: string }).session_id;
        window.location.href = getWebChatUrl(sessionId);
      } else {
        setBtnText("Join");
        setErrorMsg(response.message);
      }
    } catch (error: any) {
      setBtnText("Join");
      setErrorMsg(error?.message || "Something went wrong");
    } finally {
      setIsBtnDisabled(false);
    }
  }

  return (
    <div
      className={cx(
        "d-flex flex-column align-items-center w-100",
        "main-content"
      )}
    >
      <div className="row w-100 py-1">
        <div className="col-12 text-white">
          <div className="d-flex justify-content-between">
            <span className="secondary-heading">Choose your hub to login</span>
            <Logout />
          </div>
        </div>
      </div>
      {errorMsg && (
        <div className="ms-4 mt-2 error-color d-flex align-items-center">
          <img src={errorIcon} alt="error" />{" "}
          <span className="ms-1">{errorMsg}</span>
        </div>
      )}
      {!isSignUp && (
        <div
          className={cx(
            "d-flex flex-column align-items-center w-100 text-white mt-1",
            "workstations"
          )}
        >
          <div className="d-flex align-items-center br-15 bg-primary-color px-1 px-xl-3 mt-2 w-100">
            <img src={searchIcon} alt={"search"} className="cursor-pointer" />
            <input
              name="password"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type={"search"}
              id="password"
              className="bg-primary-color ps-2 py-1 py-md-2 py-xl-3 text-white w-100 br-15"
              placeholder="Search"
            />
          </div>
          {isLoading ? (
            <div
              className={cx(
                "bg-primary-color w-100 br-15 mt-2",
                "fetching-workstations"
              )}
            >
              Fetching workstations...
            </div>
          ) : filteredWorkStations?.length ? (
            filteredWorkStations.map((workstation) => (
              <div
                key={workstation.id}
                className={cx("w-100 br-15 my-1", {
                  "bg-secondary-color padding-1px":
                    workstation.id === selectedWorkstation.id,
                })}
              >
                <div
                  className={cx(
                    "text-white bg-primary-color w-100 d-flex align-items-center py-3 ms-0",
                    "br-15"
                  )}
                  role="button"
                  onClick={() =>
                    setSelectedWorkstation((prev) => {
                      if (prev.id === workstation.id) {
                        return {
                          ...prev,
                          id: null,
                        };
                      }
                      return workstation;
                    })
                  }
                >
                  <span className="ms-2 ms-lg-3">
                    {workstation.logo ? (
                      <img
                        src={workstation.logo}
                        alt={workstation.name || "workstation"}
                        className={cx("logo", "workstation-logo")}
                      />
                    ) : (
                      <div
                        className={cx("img-alt", "workstation-logo")}
                        style={{ backgroundColor: workstation.color! }}
                      >
                        {workstation.name?.charAt(0)}
                      </div>
                    )}
                  </span>
                  <span className="d-flex align-items-center justify-content-between w-100">
                    <span className="d-flex flex-column ms-2 ms-lg-3">
                      <span className={cx("workstation-name")}>
                        {workstation.name}
                      </span>
                      {workstation.website_link && (
                        <span className={cx("workstation-website")}>
                          {workstation.website_link}
                        </span>
                      )}
                    </span>
                    <img
                      src={rightArrow}
                      alt="learn-more"
                      className={cx("me-3 me-lg-4 me-xxl-5", "arrow")}
                    />
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div
              className={cx(
                "bg-primary-color w-100 br-15 mt-2",
                "fetching-workstations"
              )}
            >
              No record found
            </div>
          )}
        </div>
      )}
      <div className={cx("row mt-4 w-100 bg-primary-color mb-5", "br-15")}>
        <div className="d-flex flex-column align-items-center p-4">
          <div className="heading2 text-white text-align-center">
            Give your team a hub where you can talk and work together
          </div>
          <div className="col-md-8 col-sm-12 margin-auto">
            <button
              type="button"
              className={cx("btn btn-lg text-white mt-3 py-3 w-100")}
              onClick={() => {
                if (btnText === "Join") {
                  handleNavigateToWorkstation(selectedWorkstation);
                } else {
                  navigate("/workstation-registration");
                }
              }}
              disabled={isBtnDisabled}
            >
              {btnText}
            </button>
          </div>
        </div>
      </div>
      <hr className="w-100 text-white mt-4" />
      <div className="row my-2 text-white">Signed in as</div>
      <div className="row heading2 text-white">{user.email}</div>
    </div>
  );
};

export default WorkstationsList;
