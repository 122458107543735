import React, { useEffect, useState } from "react";

// components
import LeftSection from "Components/LeftSection/LeftSection";
import Home from "Components/Pages/Home/Home";
import Email from "Components/Pages/Email/Email";
import VerificationCode from "Components/Pages/VerificationCode/VerificationCode";
import Passwords from "Components/Pages/Passwords/Passwords";
import WorkstationRegistration from "Components/Pages/WorkstationRegistration/WorkstationRegistration";
import Subscribe from "Components/Pages/Subscribe/Subscribe";
import InviteCoworkers from "Components/Pages/InviteCoworkers/InviteCoworkers";
import WorkstationsList from "Components/Pages/WorkstationsList/WorkstationsList";
import PrivateRoute from "Components/Routes/PrivateRoute";
import TermsAndConditions from "Components/Pages/TermsAndConditions/TermsAndConditions";
import Webchat from "Components/Pages/Webchat/Webchat";
import ResetPassword from "Components/Pages/ResetPassword/ResetPassword";
import ForgotPassword from "Components/Pages/ForgotPassword/ForgotPassword";
import AcceptInvitation from "Components/Pages/AcceptInvitation/AcceptInvitation";
import UserDetails from "Components/Pages/UserDetails/UserDetails";
import PublicRoute from "Components/Routes/PublicRoute";

// packages
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices/user";
import { AxiosResponse } from "axios";

// css
import "./App.css";

// assets
import logo from "assets/logo.svg";

import { userService } from "services";
import { ResponseData, User } from "interfaces";
import { logout } from "helpers";

const token = localStorage.getItem("token");

const App: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(!!token);

  useEffect(() => {
    if (token) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUserDetails() {
    try {
      setIsLoading(true);
      const response: AxiosResponse = await userService.getUserDetails();
      const responseData: ResponseData = response.data;
      if (!responseData.success) {
        logout();
      } else {
        const userData = responseData.data as User;
        dispatch(setUser(userData));
      }
    } catch (error) {
      logout();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <BrowserRouter>
      <div className="container-fluid bg-primary-color container-main m-0 p-0">
        <div className="row">
          <LeftSection />
          <div className="col-md-12 col-lg-6 right-section bg-color-black d-flex flex-column justify-content-center px-1 px-md-5 position-relative">
            <div className="row">
              <div className="col-xs-8 d-lg-none text-align-center my-4">
                <img className="w-75" src={logo} alt="HookzOffice" />
              </div>
            </div>
            {isLoading ? (
              <div>Fetch details</div>
            ) : (
              <Routes>
                <Route
                  path="/"
                  element={
                    <PublicRoute>
                      <Home />
                    </PublicRoute>
                  }
                />
                <Route>
                  {["/sign-up", "/sign-in"].map((path, index) => (
                    <Route
                      path={path}
                      key={index}
                      element={
                        <PublicRoute>
                          <Email />
                        </PublicRoute>
                      }
                    />
                  ))}
                </Route>
                <Route
                  path="/verification-code"
                  element={
                    <PublicRoute>
                      <VerificationCode />
                    </PublicRoute>
                  }
                />
                <Route>
                  {["/password", "/setup-password"].map((path, index) => (
                    <Route
                      path={path}
                      key={index}
                      element={
                        <PublicRoute>
                          <Passwords />
                        </PublicRoute>
                      }
                    />
                  ))}
                </Route>
                <Route
                  path="/workstation-registration"
                  element={
                    <PrivateRoute>
                      <WorkstationRegistration />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/subscribe"
                  element={
                    <PrivateRoute>
                      <Subscribe />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/invite-coworkers"
                  element={
                    <PrivateRoute>
                      <InviteCoworkers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workstations"
                  element={
                    <PrivateRoute>
                      <WorkstationsList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/webchat"
                  element={
                    <PrivateRoute>
                      <Webchat />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/accept-invitation/:encodedData"
                  element={
                    // <PublicRoute>
                      <AcceptInvitation />
                    // </PublicRoute>
                  }
                />
                <Route
                  path="/terms-and-conditions"
                  element={
                    <PrivateRoute>
                      <TermsAndConditions />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/forgot-password"
                  element={
                    <PublicRoute>
                      <ForgotPassword />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/reset-password/:encodedData"
                  element={
                    <PublicRoute>
                      <ResetPassword />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/user-details"
                  element={
                    <PrivateRoute>
                      <UserDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="*"
                  element={<Navigate to={"/"} />}
                />
              </Routes>
            )}
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
