/** @format */

import React, {
  ChangeEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

// packages
import classNames from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

// assets
import logo from "assets/mini-logo.svg";
import openEye from "assets/icons/open-eye.svg";
import closedEye from "assets/icons/closed-eye.svg";
import errorIcon from "assets/icons/error-icon.svg";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import microsoft from "assets/icons/microsoft.svg";

// css
import styles from "./passwords.module.css";

import { userService } from "services";
import { RootState } from "store";
import { ResponseData, User } from "interfaces";
import { setUser } from "store/slices/user";
import { GlobalConfig } from "config";
import { logout } from "helpers";

const cx = classNames.bind(styles);

interface Passwords {
  password: string;
  confirmPassword: string;
}

const { passwordRegex } = GlobalConfig;

const Passwords: React.FC = (): JSX.Element => {
  const { state } = useLocation();
  const isSignUp = state?.isSignUp;
  const email = state?.email;
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AnyAction> = useDispatch();

  const { user } = useSelector(
    (state: RootState) => state.user
  );

  const passwordRef: RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const confirmPasswordRef: RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);

  const [passwords, setPasswords] = useState<Passwords>({
    password: "",
    confirmPassword: "",
  });
  const [isBtnDisabled, setIsBtnDisabled]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [error, setError]: [string, (error: string) => void] =
    useState<string>("");
  const [btnText, setBtnText]: [string, (btnText: string) => void] =
    useState<string>("Next");
  const [passwordError, setPasswordError]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError]: [
    string,
    (password: string) => void
  ] = useState<string>("");
  const [showPassword, setShowPassword]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);


  useEffect(() => {
    if(isSignUp){
      if(!passwords.password || !passwords.confirmPassword){
        setIsBtnDisabled(true)
      }else{
        setIsBtnDisabled(false)
      }
    }else {
      if(!passwords.password){
        setIsBtnDisabled(true)
      }else{
        setIsBtnDisabled(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwords])

  function toggleBtnDisablity(): void {
    setIsBtnDisabled((prev: boolean): boolean => !prev);
  }

  function togglePasswordVisibility(): void {
    setShowPassword((prev: boolean): boolean => !prev);
  }

  function toggleConfirmPasswordVisibility(): void {
    setShowConfirmPassword((prev: boolean): boolean => !prev);
  }

  async function handleNext(): Promise<void> {
    try {
      toggleBtnDisablity();
      const { password, confirmPassword } = passwords;
      if (passwordError && password) {
        setPasswordError(false);
      }
      if (confirmPasswordError && confirmPassword) {
        setConfirmPasswordError("");
      }
      if (password && confirmPassword && error) {
        if (password === confirmPassword) {
          setError("");
        }
      }
      if (password && isSignUp) {
        if (!password.match(passwordRegex)) {
          setPasswordError(true);
          return;
        } else {
          setPasswordError(false);
        }
      }
      const { setUpPassword, signIn } = userService;
      let api = setUpPassword;
      let payload: object = {
        username: user.id,
        code: state.verificationCode,
        password,
        cpassword: confirmPassword,
      };

      if (!isSignUp) {
        if (!password) {
          setPasswordError(true);
          return;
        }
        api = signIn;
        payload = {
          email,
          password,
        };
      } else if (!password && !confirmPassword) {
        setPasswordError(true);
        setConfirmPasswordError("Confirm password is required");
        return;
      }
      if (password !== confirmPassword && isSignUp) {
        setError("Two passwords are not same");
        return;
      }
      setBtnText("Loading...");
      const response: AxiosResponse = await api(payload);
      const responseData: ResponseData = response.data;
      if (!responseData.success) {
        setError(responseData.message);
        return;
      } else {
        const userData: User = responseData.data as User;
        localStorage.setItem("token", userData.token!);
        dispatch(setUser({ ...user, ...userData }));
        const acceptInvitationEncodedData = localStorage.getItem('invitation_encoded_data');
        let route = "/workstations";
        if (!userData?.nickname) {
          route = "/user-details";
        } else if (acceptInvitationEncodedData) {
          route = `/accept-invitation/${acceptInvitationEncodedData}`;
        }
        navigate(route, { state: { isSignUp } });
      }
    } catch (error: any) {
      setError(error?.message || "Something went wrong");
    } finally {
      toggleBtnDisablity();
      setBtnText("Next");
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setPasswords((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  return (
    <div
      className={cx(
        "row bg-primary-color p-1 p-md-3 p-xxl-5 global-text-color justify-content-center align-items-center flex-column br-15 right-section-box"
      )}
    >
      <div className="col-12 text-center primary-heading">
        <div className="d-flex align-items-center justify-content-center">
          <span>Sign {!isSignUp ? "In" : "Up"} for</span>
          <img
            className="mx-2 hookzoffice-card-logo"
            src={logo}
            alt="hookzOffice"
          />{" "}
        </div>
        <span>free plan, It's quick.</span>
      </div>
      {error && (
        <div className="error-color d-flex align-items-center justify-content-center mt-5">
          <img src={errorIcon} alt="error" />{" "}
          <span className="ms-1">{error}</span>
        </div>
      )}
      <div className="col-12 col-xl-9 mt-5">
        <label className="ms-4" htmlFor="password">
          {isSignUp && "New"} Password
        </label>
        <div className="d-flex align-items-center input-field bg-color-grey input-width px-4 mt-2 w-100">
          <input
            name="password"
            value={passwords.password}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            id="password"
            className="bg-color-black py-3 text-white w-100"
            placeholder={`${isSignUp ? "New " : ""}Password`}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (!isSignUp) {
                  handleNext();
                } else {
                  if (confirmPasswordRef.current) {
                    confirmPasswordRef.current.focus();
                  }
                }
              }
            }}
          />
          <div role="button" onClick={togglePasswordVisibility}>
            <img
              src={showPassword ? openEye : closedEye}
              alt={showPassword ? "hide" : "show"}
              className="password-open-close-eye"
            />
          </div>
        </div>
        {passwordError && (
          <div className="ms-4">
            <div className="d-flex align-items-center error-color">
              <img src={errorIcon} alt="error" />{" "}
              <span className="ms-1">
                Password should have atleast one uppercase letter
              </span>
            </div>
            <div className="d-flex align-items-center error-color">
              <img src={errorIcon} alt="error" />{" "}
              <span className="ms-1">
                Password should have atleast one digit
              </span>
            </div>
            <div className="d-flex align-items-center error-color">
              <img src={errorIcon} alt="error" />{" "}
              <span className="ms-1">
                Password should have atleast one symbol
              </span>
            </div>
            <div className="d-flex align-items-center error-color">
              <img src={errorIcon} alt="error" />{" "}
              <span className="ms-1">
                Password should have atleast one lowercase
              </span>
            </div>
            <div className="d-flex align-items-center error-color">
              <img src={errorIcon} alt="error" />{" "}
              <span className="ms-1">
                Password should have minimum 6 characters
              </span>
            </div>
          </div>
        )}
      </div>
      {isSignUp ? (
        <div className="col-12 col-xl-9 mt-3">
          <label className="ms-4" htmlFor="confirm-password">
            Confirm Password
          </label>
          <div className="d-flex align-items-center input-field bg-color-grey input-width px-4 mt-2 w-100">
            <input
              ref={confirmPasswordRef}
              name="confirmPassword"
              value={passwords.confirmPassword}
              onChange={handleChange}
              type={showConfirmPassword ? "text" : "password"}
              id="confirm-password"
              className="py-3 text-white w-100"
              placeholder="Confirm Password"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleNext();
                }
              }}
            />
            <div role="button" onClick={toggleConfirmPasswordVisibility}>
              <img
                src={showConfirmPassword ? openEye : closedEye}
                alt={showConfirmPassword ? "hide" : "show"}
                className="password-open-close-eye"
              />
            </div>
          </div>
          {confirmPasswordError && (
            <div className="d-flex align-items-center error-color ms-4">
              <img src={errorIcon} alt="error" />
              <span className="ms-1">{confirmPasswordError}</span>
            </div>
          )}
        </div>
      ) : (
        <div className="col-12 text-center mt-2">
          <div
            className="link"
            role="button"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password?
          </div>
        </div>
      )}
      <div className="col-12 col-xl-9 mt-4">
        <button
          type="button"
          className={cx("btn w-100 text-white py-3 py-xxxl-4", "btn")}
          onClick={handleNext}
          disabled={isBtnDisabled}
        >
          {btnText}
        </button>
        <div className="col-12 text-center mt-5">
          <div className={cx("mt-8")}>
            <span>More ways to sign up</span>
          </div>
          <div className="mt-3">
            <img src={google} alt="google" />
            <img src={facebook} alt="facebook" className="mx-3" />
            <img src={microsoft} alt="microsoft" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Passwords;
