import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

// packages
import {
  Link,
  useLocation,
  Location,
  useNavigate,
  NavigateFunction,
} from "react-router-dom";
import classNames from "classnames/bind";

// assets
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import microsoft from "assets/icons/microsoft.svg";
import logo from "assets/mini-logo.svg";
import errorIcon from "assets/icons/error-icon.svg";

// css
import styles from "./email.module.css";
import { userService } from "services";
import { User, ResponseData } from "interfaces";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { setUser } from "store/slices/user";

import { GlobalConfig } from "config";

const { emailRegex } = GlobalConfig;
const cx = classNames.bind(styles);

const Email: React.FC = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch<AnyAction> = useDispatch();

  const location: Location = useLocation();
  const isSignUp: boolean = location.pathname === "/sign-up";

  const [email, setEmail]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>("");
  const [isBtnDisabled, setIsBtnDisabled]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(true);
  const [error, setError]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("");
  const [btnText, setBtnText]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("Next");

useEffect(() => {
  if(email){
    setIsBtnDisabled(false)
  }else{
    setIsBtnDisabled(true);
  }
}, [email])

  async function handleNext(): Promise<void> {
    try {
      setIsBtnDisabled(true);
      if (!email?.match(emailRegex)) {
        showError("Please enter a valid business email");
        return;
      }
      if (!isSignUp) {
        navigate("/password", { state: { email, isSignUp, isSignIn: true } });
        return;
      }

      setBtnText("Loading...");
      const { signUp, resendVerificationCode } = userService;
      const response: AxiosResponse = await signUp({
        email,
      });
      const responseData = response.data as ResponseData;
      if (!responseData.success) {
        showError(responseData.message);
      } else {
        let userData = responseData.data as User;
        if (!userData?.id) {
          const response: AxiosResponse = await resendVerificationCode({
            email,
          });
          const responseData = response.data as ResponseData;
          if (!responseData.success) {
            showError(responseData.message);
            return;
          }
          userData = responseData.data as User;
        }
        dispatch(setUser(userData));
        navigate("/verification-code");
      }
    } catch (error: any) {
      showError(error?.message || "Something went wrong");
    } finally {
      setIsBtnDisabled(false);
      setBtnText("Next");
    }
  }

  function showError(errorMessage: string): void {
    setError(errorMessage);
  }

  return (
    <div
      className={cx(
        "row bg-primary-color p-1 p-md-3 p-xxl-5 global-text-color justify-content-center align-items-center flex-column br-15 right-section-box"
      )}
    >
      <div className="col-12 text-center primary-heading">
        <div className="d-flex align-items-center justify-content-center">
          <span className={cx("sign-up-for")}>
            Sign {isSignUp ? "Up" : "In"} for
          </span>
          <img
            className="mx-2 hookzoffice-card-logo"
            src={logo}
            alt="hookzOffice"
          />{" "}
        </div>
        <span>free plan, It's quick.</span>
      </div>
      <div className="col-12 col-xl-9 mt-5">
        <label className="ms-4" htmlFor="email">
          Email Address
        </label>
        <input
          type="text"
          id="email"
          onChange={e => setEmail(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleNext();
            }
          }}
          className={cx("py-3 px-4 w-100 mt-2 text-white", "email")}
          placeholder="Enter your business/work email"
        />
        {error && (
          <div className="error-color d-flex align-items-center">
            <img src={errorIcon} alt="error" />{" "}
            <span className="ms-1">{error}</span>
          </div>
        )}
      </div>
      <div className="col-12 col-xl-9 mt-4">
        <button
          type="button"
          className={cx("btn w-100 text-white py-3 py-xxxl-4")}
          onClick={handleNext}
          disabled={isBtnDisabled}
        >
          {btnText}
        </button>
      </div>
      <div className="col-12 text-center mt-2">
        <div>
          <Link to={isSignUp ? "/sign-in" : "/sign-up"} className="link">
            {isSignUp ? "Sign in to a hub" : "Sign up"}
          </Link>
        </div>
      </div>
      <div className="col-12 text-center mt-5">
        <div className="mt-5">
          <span>More ways to sign up</span>
        </div>
        <div className="mt-3">
          <img src={google} alt="google" />
          <img src={facebook} alt="facebook" className="mx-3" />
          <img src={microsoft} alt="microsoft" />
        </div>
      </div>
    </div>
  );
};

export default Email;
