/**
 * /*
 * These are the placeholder roles you can replace these with yours
 *
 * @format
 */

interface GlobalConfig {
  baseUrl: string;
  v2: string;
  v3: string;
  emailRegex: RegExp;
  passwordRegex: RegExp
  WEB_CHAT_CLEAR_SESSION_URL: string;
  APIM_APIS: {
    fireBaseSessionBuild: string;
    getFireBaseSession: string;
    syncContactsOnAppOpenViaCS: string;
  };
  COMERA_BACKEND_URL: {
    userPepNodes: string;
  };
  WEB_CHAT_URL: string,
}

const {
  REACT_APP_API_BASE_URL,
  REACT_APP_CLEAR_SESSION_URL,
  REACT_APP_APIM_URL,
  REACT_APP_API_COMERA_BACKEND_URL,
  REACT_APP_COVERSE_URL
} = process.env;

const globalConfig: GlobalConfig = {
  baseUrl: REACT_APP_API_BASE_URL!,
  v2: "v2.0",
  v3: "v3.0",
  emailRegex: /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
  WEB_CHAT_CLEAR_SESSION_URL: REACT_APP_CLEAR_SESSION_URL!,
  APIM_APIS: {
    fireBaseSessionBuild: `${REACT_APP_APIM_URL}fireBaseSessionBuild`,
    getFireBaseSession: `${REACT_APP_APIM_URL}getFireBaseSession`,
    syncContactsOnAppOpenViaCS: `${REACT_APP_APIM_URL}syncContactsOnAppOpenViaCS`,
  },
  COMERA_BACKEND_URL: {
    userPepNodes: `${REACT_APP_API_COMERA_BACKEND_URL}users/pep-nodes`,
  },
  WEB_CHAT_URL: `${REACT_APP_COVERSE_URL}?session_id=`,
};

export default globalConfig;
