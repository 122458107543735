import { GlobalConfig } from "config";
import { authHeader, handleError, handleResponse } from "helpers";
import axios, { AxiosResponse } from "axios";

const { baseUrl, v3 } = GlobalConfig;

export const inviteService = {
  inviteCoWorkers,
};

function inviteCoWorkers(data: object): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v3}/send_invitation`, data, {
      headers: authHeader(),
    })
    .then(handleResponse)
    .catch(handleError);
}
