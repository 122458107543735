import { GlobalConfig } from "../config/";
import axios from "axios";

const { WEB_CHAT_CLEAR_SESSION_URL, APIM_APIS, COMERA_BACKEND_URL } =
  GlobalConfig;
const { syncContactsOnAppOpenViaCS, fireBaseSessionBuild } = APIM_APIS;
const { userPepNodes } = COMERA_BACKEND_URL;

export const saveSessionToFirebaseInServer = async function (dataz: object) {
  return new Promise(async (resolve, reject) => {
    try {
      dataz = {
        clear_session_url: WEB_CHAT_CLEAR_SESSION_URL,
        syncContactsOnAppOpenViaCS,
        userPepNodes,
        theme: 'dracula',
        ...dataz,
      };
      let stringifyData = { json_data: `'${JSON.stringify(dataz)}'` };
      const res = await axios.post(fireBaseSessionBuild, stringifyData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
