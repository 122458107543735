import { GlobalConfig } from "config";
import { authHeader, handleError, handleResponse } from "helpers";
import axios, { AxiosResponse } from "axios";

const { baseUrl, v3 } = GlobalConfig;

export const plansService = {
  getPlansList,
};

function getPlansList(): Promise<AxiosResponse> {
  return axios
    .post(
      `${baseUrl}/${v3}/my_companies`,
      { limit: 5, page: 1 },
      {
        headers: authHeader(),
      }
    )
    .then(handleResponse)
    .catch(handleError);
}
