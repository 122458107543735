import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "interfaces";

interface UserState {
  user: User; // Provide the type for the user object
  acceptInvitationEncodedData: string;
}

const initialState: UserState = {
  user: {
    id: "",
    bio: null,
    created_at: "",
    dob: null,
    email: "",
    first_name: null,
    gender: null,
    last_login_at: null,
    last_name: null,
    mobile: null,
    nickname: null,
    password: "",
    qr_code_regenerated_at: null,
    status: 0,
    updated_at: null,
    username: null,
    userId: null,
  },
  acceptInvitationEncodedData: "",
};

// Slice
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setAcceptInvitationEncodedData: (state, action: PayloadAction<string>) => {
      state.acceptInvitationEncodedData = action.payload;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUser, setAcceptInvitationEncodedData } = slice.actions;
