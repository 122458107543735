import React, { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface PrivateRouteProps {
  children: React.ReactElement;
}

export default function PrivateRoute({
  children,
}: PrivateRouteProps): React.ReactElement | null {
  const token: string | null = localStorage.getItem("token");
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    if(!token) {
      navigate('/');
    }
  }, [token, navigate]);

  return children as React.ReactElement;
}
