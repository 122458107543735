import React from "react";

import back from "assets/icons/back-arrow.svg";

import styles from "./termsAndConditions.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const TermsAndConditions = () => {
  return (
    <div
      className={cx(
        "d-flex flex-column justify-content-center align-items-center bg-color-black bg-primary-color h-100 px-1 px-md-5",
        "main"
      )}
    >
      <div className={cx("heading", "text-white")}>Terms & Conditions</div>

      {/* <div
        className={cx(
          "d-flex flex-column align-items-center bg-primary-color p-4 mt-3 br-15",
          "box",
          "content-container"
        )}
      >
        <div className="d-flex flex-column align-items-center position-relative">
           <img
            src={back}
            alt="back"
            className={cx("position-absolute", "back")}
          /> 
          <div className="d-flex justify-content-center text-align-center">
            <div className={cx("text-white text-align-center mb-0")}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Adipiscing diam donec adipiscing tristique risus nec feugiat.
              Viverra orci sagittis eu volutpat. Vel facilisis volutpat est
              velit egestas dui. Sed elementum tempus egestas sed sed risus
              pretium quam vulputate. Sit amet porttitor eget dolor morbi non
              arcu risus. Donec et odio pellentesque diam volutpat commodo sed
              egestas egestas. Quis auctor elit sed vulputate mi sit amet
              mauris. Blandit libero volutpat sed cras ornare arcu dui vivamus.
              Scelerisque felis imperdiet proin fermentum leo vel. Viverra nibh
              cras pulvinar mattis. Integer quis auctor elit sed vulputate mi
              sit. Nam libero justo laoreet sit amet cursus. Sed felis eget
              velit aliquet. Eleifend mi in nulla posuere sollicitudin aliquam
              ultrices sagittis. Adipiscing tristique risus nec feugiat in. Hac
              habitasse platea dictumst vestibulum rhoncus est. Mattis
              pellentesque id nibh tortor id aliquet lectus proin. Sit amet
              consectetur adipiscing elit pellentesque. Tincidunt praesent
              semper feugiat nibh sed pulvinar proin gravida hendrerit. Nulla
              aliquet enim tortor at auctor urna nunc id cursus. Ultricies leo
              integer malesuada nunc vel risus commodo viverra. Tincidunt eget
              nullam non nisi est. Maecenas accumsan lacus vel facilisis
              volutpat est velit egestas. Orci ac auctor augue mauris.
              Adipiscing enim eu turpis egestas pretium aenean pharetra magna
              ac. Orci a scelerisque purus semper eget duis. Orci eu lobortis
              elementum nibh tellus. Quis auctor elit sed vulputate mi.
              Fermentum posuere urna nec tincidunt praesent semper feugiat nibh
              sed. Mattis pellentesque id nibh tortor id. Sit amet commodo nulla
              facilisi. Semper quis lectus nulla at volutpat diam ut venenatis
              tellus. Sem viverra aliquet eget sit. Nibh mauris cursus mattis
              molestie a iaculis. Malesuada fames ac turpis egestas sed. Vitae
              et leo duis ut diam quam nulla. Risus nec feugiat in fermentum.
              Eget arcu dictum varius duis at consectetur. Mattis rhoncus urna
              neque viverra justo nec ultrices. Netus et malesuada fames ac. In
              cursus turpis massa tincidunt dui. Nibh praesent tristique magna
              sit amet purus. In fermentum et sollicitudin ac orci phasellus
              egestas tellus. Leo integer malesuada nunc vel risus. Ipsum nunc
              aliquet bibendum enim facilisis gravida neque. Fames ac turpis
              egestas integer eget aliquet nibh. Nibh nisl condimentum id
              venenatis. Elementum nibh tellus molestie nunc non. In eu mi
              bibendum neque egestas congue quisque egestas. Nibh ipsum
              consequat nisl vel pretium lectus quam id leo. Blandit cursus
              risus at ultrices. Duis tristique sollicitudin nibh sit amet
              commodo. Pellentesque eu tincidunt tortor aliquam. Id consectetur
              purus ut faucibus pulvinar. Amet est placerat in egestas erat
              imperdiet sed euismod nisi. Pharetra convallis posuere morbi leo
              urna molestie at elementum eu. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Adipiscing diam donec adipiscing
              tristique risus nec feugiat. Viverra orci sagittis eu volutpat.
              Vel facilisis volutpat est velit egestas dui. Sed elementum tempus
              egestas sed sed risus pretium quam vulputate. Sit amet porttitor
              eget dolor morbi non arcu risus. Donec et odio pellentesque diam
              volutpat commodo sed egestas egestas. Quis auctor elit sed
              vulputate mi sit amet mauris. Blandit libero volutpat sed cras
              ornare arcu dui vivamus. Scelerisque felis imperdiet proin
              fermentum leo vel. Viverra nibh cras pulvinar mattis. Integer quis
              auctor elit sed vulputate mi sit. Nam libero justo laoreet sit
              amet cursus. Sed felis eget velit aliquet. Eleifend mi in nulla
              posuere sollicitudin aliquam ultrices sagittis. Adipiscing
              tristique risus nec feugiat in. Hac habitasse platea dictumst
              vestibulum rhoncus est. Mattis pellentesque id nibh tortor id
              aliquet lectus proin. Sit amet consectetur adipiscing elit
              pellentesque. Tincidunt praesent semper feugiat nibh sed pulvinar
              proin gravida hendrerit. Nulla aliquet enim tortor at auctor urna
              nunc id cursus. Ultricies leo integer malesuada nunc vel risus
              commodo viverra. Tincidunt eget nullam non nisi est. Maecenas
              accumsan lacus vel facilisis volutpat est velit egestas. Orci ac
              auctor augue mauris. Adipiscing enim eu turpis egestas pretium
              aenean pharetra magna ac. Orci a scelerisque purus semper eget
              duis. Orci eu lobortis elementum nibh tellus. Quis auctor elit sed
              vulputate mi. Fermentum posuere urna nec tincidunt praesent semper
              feugiat nibh sed. Mattis pellentesque id nibh tortor id. Sit amet
              commodo nulla facilisi. Semper quis lectus nulla at volutpat diam
              ut venenatis tellus. Sem viverra aliquet eget sit. Nibh mauris
              cursus mattis molestie a iaculis. Malesuada fames ac turpis
              egestas sed. Vitae et leo duis ut diam quam nulla. Risus nec
              feugiat in fermentum. Eget arcu dictum varius duis at consectetur.
              Mattis rhoncus urna neque viverra justo nec ultrices. Netus et
              malesuada fames ac. In cursus turpis massa tincidunt dui. Nibh
              praesent tristique magna sit amet purus. In fermentum et
              sollicitudin ac orci phasellus egestas tellus. Leo integer
              malesuada nunc vel risus. Ipsum nunc aliquet bibendum enim
              facilisis gravida neque. Fames ac turpis egestas integer eget
              aliquet nibh. Nibh nisl condimentum id venenatis. Elementum nibh
              tellus molestie nunc non. In eu mi bibendum neque egestas congue
              quisque egestas. Nibh ipsum consequat nisl vel pretium lectus quam
              id leo. Blandit cursus risus at ultrices. Duis tristique
              sollicitudin nibh sit amet commodo. Pellentesque eu tincidunt
              tortor aliquam. Id consectetur purus ut faucibus pulvinar. Amet
              est placerat in egestas erat imperdiet sed euismod nisi. Pharetra
              convallis posuere morbi leo urna molestie at elementum eu.
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TermsAndConditions;
