import React from "react";

// packages
import {
  Link,
  NavigateFunction,
  useNavigate,
  useParams,
} from "react-router-dom";
import classNames from "classnames/bind";

// assets
import people from "assets/people.svg";
import invitedToHookzOffice from "assets/invited-to-hookzoffice.svg";

// css
import styles from "./home.module.css";

const cx = classNames.bind(styles);

const Home: React.FC = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  function handleClick(route: string): () => void {
    return () => {
      navigate(route);
    };
  }

  return (
      <div className="d-flex flex-column justify-content-center text-white h-100 w-100">
          <div
            className={cx(
              "heading",
              "mx-2 mx-lg-4 mx-xxxl-5 d-flex justify-content-center"
            )}
          >
            <div>
              <div>
                Think <span className={cx("socialMedia")}>social media</span>;
              </div>
              <div>
                purpose built for{" "}
                <span className={cx("businessCommunication")}>
                  business communication
                </span>
                .
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <img src={people} alt="people" className="w-50" />
          </div>
          <div className={cx(`d-flex flex-column align-items-center w-100`)}>
            <button
              type="button"
              className={cx(
                "btn text-white mt-5 py-1 py-md-2 py-lg-3 py-xxxl-4",
                "signInBtn"
              )}
              onClick={handleClick(`/sign-in`)}
            >
              Sign In
            </button>
            <button
              type="button"
              className={cx(
                "btn text-white mt-3 py-1 py-md-2 py-lg-3 py-xxxl-4",
                "signUpBtn"
              )}
              onClick={handleClick(`/sign-up`)}
            >
              Sign Up
            </button>
          </div>
      </div>
  );
};

export default Home;
