import { GlobalConfig } from "config";
import { authHeader, handleError, handleResponse } from "helpers";
import axios, { AxiosResponse } from "axios";

const { baseUrl, v2, v3 } = GlobalConfig;

export const workstationsService = {
  workstationRegistration,
  getWorkstationsList,
  uploadImageAndGetLink,
  invitationAction
};

function workstationRegistration(data: object): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v3}/company_registration`, data, {
      headers: authHeader(),
    })
    .then(handleResponse)
    .catch(handleError);
}

function getWorkstationsList(
  data: object
): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v3}/my_companies`, data, {
      headers: authHeader(),
    })
    .then(handleResponse)
    .catch(handleError);
}

function uploadImageAndGetLink(
  data: object
): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v2}/uploadImageAndGetLink`, data)
    .then(handleResponse)
    .catch(handleError);
}


function invitationAction(data: object): Promise<AxiosResponse> {
  return axios
    .post(`${baseUrl}/${v3}/invitationAction`, data, {
      headers: authHeader(),
    })
    .then(handleResponse)
    .catch(handleError);
}
