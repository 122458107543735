import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import classNames from "classnames/bind";
import styles from "./inviteCoworkers.module.css";

// assets
import back from "assets/icons/back-arrow.svg";
import add from "assets/icons/add.svg";
import remove from "assets/icons/remove.svg";
import errorIcon from "assets/icons/error-icon.svg";

import { GlobalConfig } from "config";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { inviteService } from "services";
import { ResponseData } from "interfaces";
import { APIResponse } from "./interfaces";
import { saveSessionToFirebaseInServer } from "services/apim.service";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { getWebChatUrl } from "helpers";

const { emailRegex } = GlobalConfig;
const cx = classNames.bind(styles);

const { REACT_APP_HOST_NAME } = process.env;

const InviteCoworkers = () => {
  const emailRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const navigate: NavigateFunction = useNavigate();
  const { user } = useSelector((state: RootState) => state.user);
  const { state } = useLocation();
  const workstation = state?.workstation;

  const [isBtnDisabled, setIsBtnDisabled]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [error, setError]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("");
  const [emailsToBeInvited, setEmailsToBeInvited]: [
    string[],
    Dispatch<SetStateAction<string[]>>
  ] = useState<string[]>([]);
  const [btnText, setBtnText]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>("Go to your hub");
  const [apiResponse, setApiResponse]: [
    APIResponse,
    Dispatch<SetStateAction<APIResponse>>
  ] = useState<APIResponse>({ message: "", success: null });

  useEffect(() => {
    if (!emailsToBeInvited.length) {
      setBtnText("Go to your hub");
    }
  }, [emailsToBeInvited]);

  const debounce = (fn: Function, ms: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (...args: any[]) {
      setIsBtnDisabled(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn(args), ms);
    };
  };

  function handleAddEmail(): void {
    setApiResponse({ message: "", success: null });
    const newEmail: string | null = emailRef.current?.value ?? null;
    if (!newEmail) {
      setIsBtnDisabled(false);
      setError("");
    } else {
      if (!newEmail?.match(emailRegex)) {
        setError("Invalid email");
      } else {
        const isEmailFound = emailsToBeInvited.find(
          (email: string) => email === newEmail
        );
        if (isEmailFound) {
          setError("Email already added");
        } else {
          if (error) {
            setError("");
          }
          setBtnText("Invite");
          setEmailsToBeInvited((prevEmails: string[]) => [
            ...prevEmails,
            newEmail,
          ]);
          if (emailRef.current) {
            emailRef.current.value = "";
          }
          setIsBtnDisabled(false);
        }
      }
    }
  }

  function removeEmail(emailToBeRemoved: string): () => void {
    return () => {
      setEmailsToBeInvited((prevEmails: string[]): string[] => {
        const filteredEmails: string[] = prevEmails.filter(
          (email: string) => email !== emailToBeRemoved
        );
        return filteredEmails;
      });
    };
  }

  async function handleInvite() {
    try {
      setIsBtnDisabled(true);
      setBtnText("Loading...");
      const emails = emailsToBeInvited.join(",");
      const response: AxiosResponse = await inviteService.inviteCoWorkers({
        company_id: workstation.id,
        emails,
      });
      const responseData = response.data as ResponseData;
      const { message, success } = responseData;
      if (success) {
        setEmailsToBeInvited([]);
      } else {
        setBtnText("Invite");
      }
      setApiResponse({ message, success });
    } catch (error: any) {
      let message = error?.message || "Something went wrong";
      setApiResponse({ message, success: 0 });
      setBtnText("Invite");
    } finally {
      setIsBtnDisabled(false);
    }
  }

  async function handleRedirect() {
    try {
      setBtnText("Redirecting to hub");
      setIsBtnDisabled(true);
      const company_id = workstation?.companyadmin?.company_id;
      const { id, email, nickname, token } = user;
      const response = (await saveSessionToFirebaseInServer({
        token,
        jid: `${id}@${REACT_APP_HOST_NAME}`,
        userId: id,
        email,
        mobile: email,
        profile: {
          nickName: nickname,
          imageUrl: "",
          privacySettings: {
            last: 1,
            avatar: 1,
          },
        },
        company_id,
      })) as ResponseData;
      const { message, success } = response;
      if (success) {
        const sessionId = (response.data as { session_id: string }).session_id;
        window.location.href = getWebChatUrl(sessionId);
      } else {
        setBtnText("Go to your hub");
        setIsBtnDisabled(false);
        setApiResponse({ message, success });
      }
    } catch (error: any) {
      setBtnText("Go to your hub");
      setIsBtnDisabled(false);
      let message = error?.message || "Something went wrong";
      setApiResponse({ message, success: 0 });
    } finally {
      setIsBtnDisabled(false);
    }
  }

  return (
    <div
      className={cx(
        "row bg-primary-color p-1 p-md-3 p-xxl-5 global-text-color justify-content-center align-items-center flex-column br-15 right-section-box"
      )}
    >
      <div className="d-flex flex-column align-items-center w-100 py-5 position-relative">
        <div role="button" onClick={() => navigate("/subscribe")}>
          <img
            src={back}
            alt="back"
            className={cx("position-absolute", "back")}
          />
        </div>
        <div className="d-flex justify-content-center text-align-center text-white secondary-heading">
          Invite your Coworkers
        </div>
      </div>
      {apiResponse.message && (
        <div
          className={cx(
            "mb-4 d-flex justify-content-center align-items-center",
            apiResponse.success ? "text-success" : "error-color"
          )}
        >
          {!apiResponse.success && <img src={errorIcon} alt="error" />}
          <span className="ms-1">{apiResponse.message}</span>
        </div>
      )}
      <div className="col-12 col-xl-9">
        <label className="ms-4" htmlFor="email">
          Invite Team Members
        </label>
        <div
          className={cx(
            "d-flex justify-content-center py-1 py-lg-2 px-1 px-lg-2 px-xxl-3 mt-2 w-100",
            "input-wrapper"
          )}
        >
          <input
            ref={emailRef}
            type="text"
            id="email"
            className={cx("input-width", "bg-color-black", "text-white")}
            placeholder="Email Address"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddEmail();
              }
            }}
            onChange={debounce(handleAddEmail, 2000)}
          />
          <div role="button" onClick={handleAddEmail}>
            <img src={add} alt="add" />
          </div>
        </div>
        {error && (
          <div className="error-color d-flex align-items-center">
            <img src={errorIcon} alt="error" />{" "}
            <span className="ms-1">{error}</span>
          </div>
        )}
      </div>
      <div className={cx("col-12 col-xl-9 emails-container mt-2")}>
        {emailsToBeInvited.map((email, index) => (
          <div
            className="d-flex align-items-center justify-content-between text-white py-1 px-1 px-lg-2 px-xxl-3"
            key={index}
          >
            <span>{email}</span>
            <img src={remove} alt="remove" className="cursor-pointer" onClick={removeEmail(email)}/>
          </div>
        ))}
      </div>
      <div className="col-12 col-xl-9 mt-4">
        <button
          type="button"
          className={cx("btn w-100 text-white py-3 py-xxxl-4")}
          onClick={() => {
            if (btnText === "Invite") {
              handleInvite();
            } else {
              handleRedirect();
            }
          }}
          disabled={isBtnDisabled}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default InviteCoworkers;
